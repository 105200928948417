import React from "react";
import ContactForm from "./ContactForm";
import { FiMail, FiMapPin } from "react-icons/fi";
import { LuCalendarClock } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import useBookingModal from "../../hooks/useBookingModal";

const ContactOne = () => {
  const { t } = useTranslation("Contact");
  const { BookingModal } = useBookingModal({ showButton: true, asLink: true });
  return (
    <>
      <div className="row row--15">
        <div className="col mx-auto">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-md-4 col-12 text-center">
                <div className="rn-address">
                  <div className="icon">
                    <LuCalendarClock />
                  </div>
                  <div className="inner">
                    <h4 className="title">{t("Want a live demo?")}</h4>
                    {BookingModal}
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-12 text-center">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">{t("Our Email Address")}</h4>
                    <p>
                      <a href="mailto:contact@predictalab.com">
                        contact@predictalab.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-12 text-center">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">{t("Our Location")}</h4>
                    <p>{t("Between Toulouse and Paris")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5">
          <iframe
            className="googlemap"
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46228.44413609146!2d1.3916432074224414!3d43.60075800878688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12aebb6fec7552ff%3A0x406f69c2f411030!2sToulouse%2C%20France!5e0!3m2!1sen!2sde!4v1700056918988!5m2!1sen!2sde"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </>
  );
};
export default ContactOne;
